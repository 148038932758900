import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import ProjectDetails from './Details.component';
import { UserContext } from 'Lib/UserProvider';

const RUN_REPORT_QUERY = gql`
  mutation CreateCodeReport(
    $repositoryUrl: String!
    $githubToken: String
    $bitbucketToken: String
    $projectId: String!
    $reportConfig: Json
  ) {
    createCodeReport(
      repositoryUrl: $repositoryUrl
      githubToken: $githubToken
      bitbucketToken: $bitbucketToken
      projectId: $projectId
      reportConfig: $reportConfig
    ) {
      status
      reportId
    }
  }
`;

const PROJECT_QUERY = gql`
  query ProjectQuery($id: ID!) {
    Project(id: $id) {
      id
      name
      repositoryUrl
      reportConfig
      reports(first: 10, orderBy: createdAt_DESC) {
        id
        createdAt
        count
        fileStats
        linesOfCode
        reportSummary
        branchName
        __typename
      }
      __typename
    }
  }
`;

const ProjectContainer = (
  props: RouteComponentProps<{ projectId: string }>,
) => {
  const { me } = useContext(UserContext);
  const projectId = props.match.params.projectId;
  const [runReportButtonText, setRunReportButtonText] = useState('Run report');
  const [isRunningReport, setIsRunningReport] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isSettingsDialogueOpen, setIsSettingsDialogueOpen] = useState(false);

  const client = useApolloClient();

  const { data, loading, refetch: refetchProject } = useQuery(PROJECT_QUERY, {
    variables: {
      id: projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const { Project } = data;

  if (!Project) {
    return <div>Sorry :(</div>;
  }

  const runReport = async () => {
    setRunReportButtonText('Running...');
    setIsRunningReport(true);
    try {
      const { data: response } = await client.mutate({
        mutation: RUN_REPORT_QUERY,
        variables: {
          githubToken: me && me.githubToken,
          bitbucketToken: me && me.bitbucketToken,
          repositoryUrl: data.Project.repositoryUrl,
          projectId: data.Project.id,
          reportConfig: data.Project.reportConfig || {},
        },
      });
      await refetchProject();
      setIsRunningReport(false);
      setRunReportButtonText('Run report');
      if (response.createCodeReport.reportId) {
        props.history.push(
          `/projects/${projectId}/reports/${
            response.createCodeReport.reportId
          }`,
        );
      }
    } catch (err) {
      console.log(err);
      setIsRunningReport(false);
      setIsErrorShown(true);
      setRunReportButtonText('Run report');
    }
  };

  const reports = Project.reports;

  return (
    <ProjectDetails
      isErrorShown={isErrorShown}
      isRunningReport={isRunningReport}
      name={Project.name}
      projectId={projectId}
      reports={reports}
      repositoryUrl={Project.repositoryUrl}
      runReport={runReport}
      runReportButtonText={runReportButtonText}
      setIsErrorShown={setIsErrorShown}
      setIsSettingsDialogueOpen={setIsSettingsDialogueOpen}
      isSettingsDialogueOpen={isSettingsDialogueOpen}
      reportConfig={Project.reportConfig}
    />
  );
};

export default withRouter(ProjectContainer);

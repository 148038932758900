const blue = '#347fea';
const green = '#66c787';
const orange = '#f5ad47';
const purple = '#825cdd';
const red = '#ea3a65';
const yellow = '#f9f974';
const gray = 'gray';

export default {
  blue,
  green,
  orange,
  purple,
  red,
  gray,
  yellow,
  primary: purple,
};

export const vendorColors = {
  githubBlack: '#24292e',
  bitbucketBlue: '#0647a5',
};

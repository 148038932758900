import React from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from 'Components/Breadcrumbs';
import { Report, ReportDetail, ReportDetailItem } from '../types';
import ReportItem from './components/ReportItem';
import Button from 'Components/Button';
import { SortOrder } from './Report.container';
import * as S from './Report.styles';

type ReportPageProps = {
  projectId: string;
  report: Report;
  reportDetail: ReportDetail;
  repositoryUrl: string;
  sortOrder: SortOrder;
  deleteReport: () => void;
  setSortOrder: (order: SortOrder) => void;
};

const FileStats = (props: { data: { [fileExtension: string]: number } }) => {
  const totalFileCount: number = Object.values(props.data).reduce(
    (acc: number, val: number) => acc + val,
    0,
  );

  return (
    <Flex mx={-3}>
      <Box mx={3}>
        Total Files Scanned: <strong>{totalFileCount}</strong>
      </Box>
      {Object.entries(props.data).map(([key, value]) => {
        return (
          <React.Fragment key={key}>
            {/* TODO: create a style for this */}
            <span style={{ borderLeft: '1px solid gray' }} />
            <Box mx={3}>
              {key}: <strong>{value}</strong>{' '}
              <em>({Number(value / totalFileCount).toFixed(2)}%)</em>
            </Box>
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

const Report = (props: ReportPageProps) => {
  const toggleSortOrder = (e: React.SyntheticEvent) => {
    props.setSortOrder(props.sortOrder === 'ASC' ? 'DESC' : 'ASC');
  };

  const reportName = props.report.reportName.replace('.json', '');

  return (
    <>
      <Breadcrumbs
        links={[
          { url: '/dashboard', text: 'Projects' },
          {
            url: `/projects/${props.projectId}`,
            text: props.report.project.name,
          },
          { text: reportName },
        ]}
      />
      <Flex width={1} my={3} justifyContent="space-between" alignItems="center">
        {props.report.fileStats && <FileStats data={props.report.fileStats} />}
        <Button onClick={toggleSortOrder}>Sort: Date {props.sortOrder}</Button>
      </Flex>

      {Object.keys(props.reportDetail).length === 0 && (
        <S.EmptyReport flexDirection="column">
          <S.IconSunny />
          <Box>Nothing found!</Box>
        </S.EmptyReport>
      )}

      {Object.keys(props.reportDetail).map((label: string) => (
        <ExpansionPanel key={label}>
          <ExpansionPanelSummary expandIcon={<IconExpandMore />}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h4>
                {label} ({props.reportDetail[label].length})
              </h4>
              <div>{props.reportDetail[label][0].description}</div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{ maxHeight: '500px', overflow: 'auto', width: '100%' }}
            >
              <ol>
                {props.reportDetail[label].map(
                  (item: ReportDetailItem, idx) => (
                    <ReportItem
                      key={idx}
                      item={item}
                      repositoryUrl={props.repositoryUrl}
                      branchName={props.report.branchName}
                    />
                  ),
                )}
              </ol>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      <Flex mt={3} justifyContent="flex-end" width={1}>
        <Button color="secondary" onClick={props.deleteReport}>
          Delete report
        </Button>
      </Flex>
    </>
  );
};

export default Report;

import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import { UserContext } from 'Lib/UserProvider';
import List from './List.component';

export type Project = {
  name: string;
  id: string;
  reports: { count: number; createdAt: string }[];
};

const UNTRACK_PROJECT_MUTATION = gql`
  mutation UntrackProject($id: ID!) {
    untrackProject(id: $id) {
      id
    }
  }
`;

const MY_PROJECTS_QUERY = gql`
  query myProjectsQuery {
    allProjects(orderBy: name_ASC) {
      id
      name
      reports(last: 16, orderBy: createdAt_ASC) {
        id
        count
        createdAt
      }
    }
  }
`;

const ListPage = () => {
  const { me } = useContext(UserContext);
  const client = useApolloClient();

  const { data, loading, refetch } = useQuery(MY_PROJECTS_QUERY, {
    variables: {
      id: me && me.id,
    },
    skip: !me,
    fetchPolicy: 'cache-and-network',
  });

  const untrackProject = (projectId: string) => async () => {
    await client.mutate({
      mutation: UNTRACK_PROJECT_MUTATION,
      variables: {
        id: projectId,
      },
    });
    await refetch();
  };

  const projects = data.allProjects || [];

  const maxItemTotalCount = projects.reduce(
    (total: number, project: Project) => {
      const maxProjectItemCount = project.reports.reduce(
        (max: number, report) => {
          if (report.count > max) {
            return report.count;
          }
          return max;
        },
        0,
      );

      if (maxProjectItemCount > total) {
        return maxProjectItemCount;
      }
      return total;
    },
    0,
  );

  return (
    <List
      projects={projects}
      maxItemTotalCount={maxItemTotalCount}
      untrackProject={untrackProject}
      isLoading={loading}
    />
  );
};

export default ListPage;

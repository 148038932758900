import { Report } from '../../../../types';

type Summary = {
  [key: string]: { value: number; _diff: number };
};

const getTotalFiles = (fileStats: Report['fileStats']) =>
  Object.values(fileStats).reduce((acc: number, val: number) => acc + val, 0);

export default (reports: Report[]) => {
  const [current, previous] = reports;

  if (!previous && !current) return {};

  // If there is only one report
  if (!previous) {
    const items: Summary = Object.entries(current.reportSummary).reduce(
      (acc, [key, value]: [string, number]) => {
        return {
          ...acc,
          [key]: {
            value,
            _diff: 0,
          },
        };
      },
      {},
    );
    items.linesOfCode = { value: current.linesOfCode, _diff: 0 };
    items.totalFiles = {
      value: getTotalFiles(current.fileStats),
      _diff: 0,
    };

    return {
      createdAt: current.createdAt,
      items,
    };
  }

  const diffs: Summary = Object.entries(current.reportSummary).reduce(
    (acc, [key, value]: [string, number]) => {
      return {
        ...acc,
        [key]: {
          value,
          _diff: value - previous.reportSummary[key] || 0,
        },
      };
    },
    {},
  );

  diffs.linesOfCode = {
    value: current.linesOfCode,
    _diff: current.linesOfCode - previous.linesOfCode,
  };

  diffs.totalFiles = {
    value: getTotalFiles(current.fileStats),
    _diff: getTotalFiles(current.fileStats) - getTotalFiles(previous.fileStats),
  };

  const summary = {
    createdAt: current.createdAt,
    items: diffs,
  };

  return summary;
};

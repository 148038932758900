import React, { ChangeEvent } from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import Button from 'Components/Button';
import * as S from './Settings.styles';
import { TextField } from '@material-ui/core';

interface FormData {
  reportConfig: string;
}

type Props = {
  formData: FormData;
  setFormData: (data: FormData) => void;
  closeDialogue: () => void;
  handleSave: () => void;
};

const Settings = (props: Props) => {
  const onChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    props.setFormData({
      ...props.formData,
      [name]: e.currentTarget.value,
    });
  };

  return (
    <Box p={4}>
      <h3>Settings</h3>
      <Box mb={3} width={1}>
        <TextField
          InputProps={{ className: S.TextField }}
          autoFocus={true}
          fullWidth={true}
          label="Report config"
          margin="normal"
          multiline={true}
          value={props.formData.reportConfig}
          variant="outlined"
          onChange={onChange('reportConfig')}
        />
      </Box>
      <Flex justifyContent="flex-end">
        <Box mr={2}>
          <Button onClick={props.closeDialogue}>Cancel</Button>
        </Box>
        <Button color="primary" variant="contained" onClick={props.handleSave}>
          Save
        </Button>
      </Flex>
    </Box>
  );
};

export default Settings;

import colors from 'Lib/theme';

export default `
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    height: 100%;
    margin: 0;
    color: #453f4a;
  }

  body {
    font-family: 'IBM Plex Mono', monospace;
    background: linear-gradient(#f7f6f9, #fbfafd);
  }

  ul {
    padding: 0 0 0 16px;
    margin: 0;
  }

  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
  }

  a {
    color: ${colors.purple};
    text-decoration: none;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import colors from 'Lib/theme';

type DiffSignProps = {
  diff: number;
  className: string;
};

const Diff = styled.div`
  display: flex;
  align-items: center;
  color: ${({ sign }: { sign: number }) =>
    sign === -1 ? colors.green : colors.red};
  font-size: 20px;
  font-weight: bold;
`;

const DiffSign = ({ diff, className }: DiffSignProps) => {
  const diffSign = Math.sign(diff);

  if (diffSign === 0) return null;

  return (
    <Diff sign={diffSign} className={className}>
      {diffSign === -1 ? (
        <ArrowDropDown color="inherit" />
      ) : (
        <ArrowDropUp color="inherit" />
      )}
      {diff}
    </Diff>
  );
};

export default DiffSign;

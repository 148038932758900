import React, { useState } from 'react';
import { useMutation } from 'react-apollo-hooks';
import Settings from './Settings';
import gql from 'graphql-tag';

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProjectMutation($projectId: ID!, $reportConfig: Json) {
    updateProject(id: $projectId, reportConfig: $reportConfig) {
      id
      reportConfig
    }
  }
`;

type Props = {
  closeDialogue: () => void;
  projectId: string;
  reportConfig: { [key: string]: string };
};

const SettingsContainer = (props: Props) => {
  const [formData, setFormData] = useState({
    reportConfig: JSON.stringify(props.reportConfig || {}, undefined, 2),
  });
  const saveProjectMutation = useMutation(UPDATE_PROJECT_MUTATION);

  const handleSave = () => {
    try {
      const reportConfig = JSON.parse(formData.reportConfig);

      saveProjectMutation({
        variables: {
          projectId: props.projectId,
          reportConfig,
        },
      });
      props.closeDialogue();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Settings
      formData={formData}
      setFormData={setFormData}
      closeDialogue={props.closeDialogue}
      handleSave={handleSave}
    />
  );
};

export default SettingsContainer;

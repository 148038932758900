import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import colors from 'Lib/theme';

export const Breadcrumbs = styled.div`
  color: ${colors.gray};
  font-size: 12px;
`;

export const BreadcrumbLink = styled(Link)`
  color: ${colors.gray};
  font-size: 12px;
  text-decoration: underline;
`;

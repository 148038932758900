import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { BoxProps } from '@rebass/grid';

export type PaperProps = BoxProps & {
  children: React.ReactNode;
};

const S = {
  Paper: styled(Box)`
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(43, 45, 50, 0.09);
  `,
};

const Paper = (props: PaperProps) => (
  <S.Paper {...props}>{props.children}</S.Paper>
);

export default Paper;

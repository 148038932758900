import React, { useState } from 'react';
import gql from 'graphql-tag';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import ReportComponent from './Report.component';
import { ReportDetail } from '../types';

export type SortOrder = 'ASC' | 'DESC';

const DELETE_REPORT_MUTATION = gql`
  mutation DeleteReportMutation($id: ID!) {
    deleteReport(id: $id) {
      id
    }
  }
`;

const REPORT_QUERY = gql`
  query ReportQuery($id: ID!) {
    Report(id: $id) {
      id
      project {
        name
        repositoryUrl
      }
      branchName
      createdAt
      fileStats
      reportName
      count
      __typename
    }
  }
`;

const REPORT_DETAILS_QUERY = gql`
  query ReportDetailQuery($projectId: ID!, $reportName: String!) {
    codeReport(projectId: $projectId, reportName: $reportName) {
      json
      id
    }
  }
`;

const ReportPageContainer = (
  props: RouteComponentProps<{ projectId: string; reportId: string }>,
) => {
  const reportId = props.match.params.reportId;
  const projectId = props.match.params.projectId;
  const [sortOrder, setSortOrder] = useState<SortOrder>('DESC');

  const client = useApolloClient();

  const { data, loading } = useQuery(REPORT_QUERY, {
    variables: {
      id: reportId,
    },
  });

  const { data: details, loading: detailsLoading } = useQuery(
    REPORT_DETAILS_QUERY,
    {
      skip: !data || !data.Report || !data.Report.reportName,
      variables: {
        projectId: projectId,
        reportName: data && data.Report && data.Report.reportName,
      },
    },
  );

  const deleteReport = async () => {
    const isConfirmed = confirm('Are you sure? This is irreversable');
    if (!isConfirmed) return;

    await client.mutate({
      mutation: DELETE_REPORT_MUTATION,
      variables: {
        id: reportId,
      },
    });
    props.history.replace(`/projects/${projectId}`);
  };

  if (loading || detailsLoading) {
    return <div>Loading...</div>;
  }

  const { Report } = data;

  if (!Report || !details) {
    return <div>Sorry :(</div>;
  }

  const reportDetail: ReportDetail = details.codeReport.json.items;

  const sortedReportItems = Object.keys(reportDetail).reduce((acc, label) => {
    const sortedItems = reportDetail[label].sort((a, b) => {
      if (!a.commitData || !b.commitData) return 1;

      if (a.commitData.timestamp && b.commitData.timestamp) {
        if (sortOrder === 'ASC') {
          return a.commitData.timestamp - b.commitData.timestamp;
        }
        return b.commitData.timestamp - a.commitData.timestamp;
      }
      return 1;
    });

    return {
      ...acc,
      [label]: sortedItems,
    };
  }, {});

  return (
    <ReportComponent
      projectId={projectId}
      report={Report}
      reportDetail={sortedReportItems}
      repositoryUrl={Report.project.repositoryUrl}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      deleteReport={deleteReport}
    />
  );
};

export default withRouter(ReportPageContainer);

import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import colors from 'Lib/theme';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.purple,
    },
  },
});

export default function ThemeProvider(props: { children: React.ReactNode }) {
  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

import React from 'react';
import Breadcrumbs from 'Components/Breadcrumbs';
import Button from 'Components/Button';
import { Box, Flex } from '@rebass/grid/emotion';
import * as S from './Create.styles';
import IconLibraryAdd from '@material-ui/icons/LibraryAdd';
import IconHttps from '@material-ui/icons/Https';

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

type Props = {
  repositories: {
    name: string;
    url: string;
    isTracked: boolean;
    isPrivate: boolean;
    description: string;
    diskUsage: number;
    languages: { name: string }[];
    owner: {
      name: string;
      avatarUrl: string;
    };
  }[];
  hasNextPage: boolean;
  isLoadingMore: boolean;
  isLoading: boolean;
  onClickLoadMore: () => void;
  onClickCreateProject: (variables: {
    name: string;
    repositoryUrl: string;
  }) => void;
};

const ProjectCreate = ({
  repositories = [],
  onClickCreateProject,
  hasNextPage,
  onClickLoadMore,
  isLoadingMore,
  isLoading,
}: Props) => {
  return (
    <>
      <Breadcrumbs
        links={[
          { url: '/dashboard', text: 'Projects' },
          { text: 'Create new' },
        ]}
      />

      <Box mt={3}>
        <h1>Track new project</h1>
      </Box>
      <Box mb={4}>
        <p>Select a repository to track</p>
      </Box>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <S.List width={1 / 2}>
          {repositories.map(repo => (
            <S.ListItem key={repo.url}>
              <Flex flexDirection="column">
                <Flex mb={2} alignItems="center" title={repo.description}>
                  {repo.name}
                  {repo.isPrivate && (
                    <S.PrivateIcon title="Private">
                      <IconHttps fontSize="inherit" />
                    </S.PrivateIcon>
                  )}
                </Flex>
                <Flex alignItems="center">
                  <S.Description alignItems="center">
                    <S.TinyAvatar avatarUrl={repo.owner.avatarUrl} />
                    {repo.owner.name}
                    <Box ml={3}>
                      {repo.diskUsage && formatBytes(repo.diskUsage)}
                    </Box>
                    <Box ml={3}>
                      {repo.languages.map(l => l.name).join(', ')}
                    </Box>
                  </S.Description>
                </Flex>
              </Flex>
              <Button
                color="primary"
                disabled={repo.isTracked}
                onClick={() =>
                  onClickCreateProject({
                    name: repo.name,
                    repositoryUrl: repo.url,
                  })
                }
              >
                <Flex mr={2}>
                  <IconLibraryAdd />
                </Flex>
                {repo.isTracked ? 'Tracking' : 'Track'}
              </Button>
            </S.ListItem>
          ))}
          {hasNextPage && (
            <Box mt={3}>
              <Button
                fullWidth={true}
                variant="outlined"
                color="primary"
                onClick={onClickLoadMore}
              >
                {isLoadingMore ? 'Loading...' : 'Load more'}
              </Button>
            </Box>
          )}
        </S.List>
      )}
    </>
  );
};

export default ProjectCreate;

import { css } from 'emotion';
import colors from 'Lib/theme';
import { transparentize } from 'polished';

export const DialogueBackdrop = css`
  && {
    background: ${transparentize(0.8, colors.purple)};
  }
`;

export const TextField = css`
  && {
    font-family: monospace;
    resize: vertical;
    overflow: auto;
    height: 100%;
  }
`;

import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import IconWbSunnyTwoTone from '@material-ui/icons/WbSunnyTwoTone';

export const EmptyReport = styled(Flex)`
  height: 60vh;
  font-size: 42px;
  color: #7b738a;
  font-weight: bold;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const IconSunny = styled(IconWbSunnyTwoTone)`
  && {
    font-size: 100px;
    color: #eac90e;
  }
`;

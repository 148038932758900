import styled from '@emotion/styled';
import Button from 'Components/Button';
import { lighten } from 'polished';
import { vendorColors } from 'Lib/theme';

export const GithubButton = styled(Button)`
  && {
    background: ${vendorColors.githubBlack};
    color: white;

    &:hover {
      background: ${lighten(0.1, vendorColors.githubBlack)};
    }
  }
`;

export const BitBucketButton = styled(Button)`
  && {
    background: ${vendorColors.bitbucketBlue};
    color: #ddeafe;

    &:hover {
      background: ${lighten(0.1, vendorColors.bitbucketBlue)};
    }
  }
`;

import React from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import Paper from 'Components/Paper';
import formatReportSummary from './lib/formatReportSummary';
import * as S from './Summary.styles';
import { Report } from 'src/modules/Project/types';
import DiffSign from 'Components/DiffSign';

type Props = {
  reports: Report[];
};

const Summary = (props: Props) => {
  const summary = formatReportSummary(props.reports);

  if (!summary.items) return null;

  return (
    <Flex flexWrap="wrap" mx={-3} my={3}>
      {Object.keys(summary.items).map((key, idx) => {
        const diff = summary.items[key]._diff;

        return (
          <Box width={[1, 1, 1 / 2, 1 / 4]} key={idx}>
            <Paper mx={3} mb={4} p={3}>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <S.Value>{summary.items[key].value}</S.Value>
                  <S.Name>{key}</S.Name>
                </Box>
                <DiffSign diff={diff} />
              </Flex>
            </Paper>
          </Box>
        );
      })}
    </Flex>
  );
};

export default Summary;

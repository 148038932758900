import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UserContext } from 'Lib/UserProvider';

const BITBUCKET_AUTHENTICATION_MUTATION = gql`
  mutation bitbucketAuthenticationMutation($bitbucketCode: String!) {
    bitbucketAuth(bitbucketCode: $bitbucketCode) {
      token
    }
  }
`;

const BitbucketAuth = (props: RouteComponentProps) => {
  const { refetchMe } = useContext(UserContext);

  const bitbucketCode = window.location.search
    .substring(1)
    .split('&')[0]
    .split('code=')[1];

  const authenticateUser = useMutation(BITBUCKET_AUTHENTICATION_MUTATION, {
    variables: {
      bitbucketCode,
    },
  });

  useEffect(() => {
    authenticateUser().then(async response => {
      localStorage.setItem('jwt', response.data.bitbucketAuth.token);
      await refetchMe();
      props.history.replace('/dashboard');
    });
  }, []);

  return <div>Authenticating...</div>;
};

export default withRouter(BitbucketAuth);

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo-hooks';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Global } from '@emotion/core';
import App from 'Components/App';
import ThemeProvider from './ThemeProvider';
import globalStyles from './globalStyles';
import UserProvider from 'Lib/UserProvider';

const httpLink = new BatchHttpLink({
  uri: process.env.GRAPHQL_SERVER_URI,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('jwt');

  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });

  if (!forward) return null;

  return forward(operation);
});

const httpLinkWithAuthToken = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache().restore((window as any).__APOLLO_STATE__),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider>
      <Global styles={globalStyles} />
      <UserProvider>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </UserProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

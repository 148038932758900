import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

export const Tooltip = styled.div`
  background: rgba(0, 0, 0, 0.7);
  padding: 8px;
  border-radius: 8px;
`;

export const TooltipItem = styled.div`
  font-size: 12px;
  color: white;
`;

export const Chart = styled(Flex)`
  background: #f7f6f9;
`;

import React, { useContext, useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import ProjectCreate from './Create.component';
import { UserContext } from 'Lib/UserProvider';

const repositoryFragment = `
  endCursor
  hasNextPage
  repositories {
    id
    name
    diskUsage
    isTracked
    isPrivate
    avatarUrl
    languages {
      name
    }
    description
    owner {
      avatarUrl
      name
    }
    createdAt
    url
    __typename
  }
`;

const LIST_GITHUB_REPOSITORIES_QUERY = gql`
  query GithubRepositories($token: ID!, $userId: ID!, $endCursor: String) {
    githubRepositories(
      githubToken: $token
      userId: $userId
      endCursor: $endCursor
    ) {
      ${repositoryFragment}
    }
  }
`;

const LIST_BITBUCKET_REPOSITORIES_QUERY = gql`
  query BitbucketRepositories($token: ID!, $userId: ID!, $endCursor: String) {
    bitbucketRepositories(
      bitbucketToken: $token
      userId: $userId
      endCursor: $endCursor
    ) {
      ${repositoryFragment}
    }
  }
`;

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($name: String, $repositoryUrl: String) {
    createProject(name: $name, repositoryUrl: $repositoryUrl) {
      id
      __typename
    }
  }
`;

const ProjectCreateContainer = (props: RouteComponentProps) => {
  const { me } = useContext(UserContext);
  const [repositories, setRepositories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNexPage] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const client = useApolloClient();

  const isGithubUser = Boolean(me && me.githubToken);

  const REPO_QUERY = isGithubUser
    ? LIST_GITHUB_REPOSITORIES_QUERY
    : LIST_BITBUCKET_REPOSITORIES_QUERY;

  const dataLabel = isGithubUser
    ? 'githubRepositories'
    : 'bitbucketRepositories';

  const token = isGithubUser ? me && me.githubToken : me && me.bitbucketToken;

  const loadRepositories = async () => {
    if (!token) return;

    const { data } = await client.query({
      query: REPO_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        token,
        userId: me && me.id,
        endCursor,
      },
    });

    const repositories = data[dataLabel].repositories;
    const newEndCursor = data[dataLabel].endCursor;
    const hasNextPage = data[dataLabel].hasNextPage;

    setRepositories(repos => {
      return [...repos, ...repositories];
    });
    setHasNexPage(hasNextPage);
    setEndCursor(newEndCursor);
    setIsLoading(false);
  };

  useEffect(() => {
    loadRepositories();
  }, []);

  const onClickCreateProject = async (variables: {
    name: string;
    repositoryUrl: string;
  }) => {
    const { data } = await client.mutate({
      mutation: CREATE_PROJECT_MUTATION,
      variables,
    });

    props.history.push(`/projects/${data.createProject.id}`);
  };

  const onClickLoadMore = async () => {
    setIsLoadingMore(true);
    await loadRepositories();
    setIsLoadingMore(false);
  };

  return (
    <ProjectCreate
      repositories={repositories}
      hasNextPage={hasNextPage}
      onClickLoadMore={onClickLoadMore}
      onClickCreateProject={onClickCreateProject}
      isLoadingMore={isLoadingMore}
      isLoading={isLoading}
    />
  );
};

export default withRouter(ProjectCreateContainer);

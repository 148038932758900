import React from 'react';
import { Box } from '@rebass/grid/emotion';
import LoggedInSidebar from './components/LoggedInSidebar';
import * as S from './Sidebar.styles';

type Props = {
  userIsLoggedIn: boolean;
};

const Sidebar = (props: Props) => {
  return (
    <S.Sidebar>
      <Box>
        <S.Logo to="/">morbo</S.Logo>
      </Box>

      {props.userIsLoggedIn && <LoggedInSidebar />}
    </S.Sidebar>
  );
};

export default Sidebar;

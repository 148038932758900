import React, { createContext, useState } from 'react';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

export type Me = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  githubToken?: string;
  bitbucketToken?: string;
  bitbucketUserId?: string;
} | null;

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      name
      email
      avatarUrl
      githubToken
      bitbucketToken
      bitbucketUserId
    }
  }
`;

export const UserContext = createContext<{
  me: Me;
  loading: boolean;
  refetchMe: (() => Promise<void>) | null;
}>({ me: null, loading: false, refetchMe: null });

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, loading, refetch } = useQuery(ME_QUERY);
  const [me, setMe] = useState(data.me);

  if (!me && data.me) {
    setMe(data.me);
  }

  const refetchMe = async () => {
    const newMe = await refetch();
    setMe(newMe.data.me);
  };

  const value = { me: me, loading, refetchMe };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;

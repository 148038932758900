import React from 'react';

import * as S from './Breadcrumbs.styles';

type LinkData = {
  url?: string;
  text: string;
};

type Props = {
  links: LinkData[];
};

const Breadcrumbs = (props: Props) => (
  <S.Breadcrumbs>
    {props.links.map((link, idx) => (
      <React.Fragment key={link.text}>
        {link.url ? (
          <S.BreadcrumbLink to={link.url}>{link.text}</S.BreadcrumbLink>
        ) : (
          <span>{link.text}</span>
        )}

        {idx < props.links.length - 1 && (
          <span style={{ margin: '0 8px' }}>/</span>
        )}
      </React.Fragment>
    ))}
  </S.Breadcrumbs>
);

export default Breadcrumbs;

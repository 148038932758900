import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid/emotion';
import IconLibraryAdd from '@material-ui/icons/LibraryAdd';
import DiffSign from 'Components/DiffSign';
import * as S from './List.styles';
import { Project } from './List.container';
import Chart from './components/Chart/Chart';
import OverflowMenu from './components/OverflowMenu';

type ListProps = {
  projects: Project[];
  maxItemTotalCount: number;
  untrackProject: (id: string) => () => void;
  isLoading: boolean;
};

const ListPage = (props: ListProps) => {
  const renderItemDiff = (reports: Project['reports']) => {
    if (!reports.length) {
      return <div>No reports run yet</div>;
    }

    if (reports.length === 1) {
      return <div>{reports[0].count} items</div>;
    }

    const diff =
      reports[reports.length - 1].count - reports[reports.length - 2].count;
    return (
      <Flex alignItems="center">
        <div>{reports[reports.length - 1].count} items </div>
        <DiffSign diff={diff} className={S.DiffStyle} />
      </Flex>
    );
  };

  return (
    <>
      <Box mb={3}>
        <h2>Projects</h2>
      </Box>
      {props.isLoading ? (
        <div>Loading...</div>
      ) : (
        <Flex flexWrap="wrap" mx={-2}>
          {props.projects.map((project: Project) => (
            <Box key={project.id} width={1 / 4}>
              <S.ProjectCard p={3} m={2}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Link to={`/projects/${project.id}`}>
                    <strong>{project.name}</strong>
                  </Link>
                  <OverflowMenu
                    projectId={project.id}
                    untrackProject={props.untrackProject}
                  />
                </Flex>

                <S.ItemDiff py={3}>
                  {renderItemDiff(project.reports)}
                </S.ItemDiff>
                <Link to={`/projects/${project.id}`}>
                  <Chart
                    reports={project.reports}
                    maxItemTotalCount={props.maxItemTotalCount}
                  />
                </Link>
              </S.ProjectCard>
            </Box>
          ))}
          <Box width={1 / 4}>
            <Link to="/projects/create">
              <S.DashedBox p={3} m={2}>
                <Box mr={2}>
                  <IconLibraryAdd color="primary" />
                </Box>
                Track New Project
              </S.DashedBox>
            </Link>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default ListPage;

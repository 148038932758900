import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { Link } from 'react-router-dom';

export const Sidebar = styled(Flex)`
  width: 200px;
  background: white;
  height: 100%;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled(Link)`
  color: #222;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
`;

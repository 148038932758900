import React, { useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UserContext } from 'Lib/UserProvider';
import Login from './Login.component';

const LoginContainer = (props: RouteComponentProps) => {
  const { me } = useContext(UserContext);

  const authenticateWithGithub = () => {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${
      process.env.GITHUB_CLIENT_ID
    }&scope=user repo`;
  };

  const authenticateWithBitbucket = () => {
    window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${
      process.env.BITBUCKET_CLIENT_ID
    }&response_type=code`;
  };

  // redirect if user is logged in
  if (me && me.id) {
    console.warn('already logged in');
    props.history.replace('/');
  }

  return (
    <Login
      authenticateWithBitbucket={authenticateWithBitbucket}
      authenticateWithGithub={authenticateWithGithub}
    />
  );
};

export default withRouter(LoginContainer);

import React, { useContext } from 'react';
import LoggedInSidebar from './LoggedInSidebar.component';
import { UserContext } from 'Lib/UserProvider';

const SidebarContainer = () => {
  const { me } = useContext(UserContext);

  const logout = () => {
    // remove token from local storage and reload page to reset apollo client
    localStorage.removeItem('jwt');
    window.location.replace('/');
  };

  return <LoggedInSidebar logout={logout} user={me} />;
};

export default SidebarContainer;

import MUIButton from '@material-ui/core/Button';
import styled from '@emotion/styled';

const Button = styled(MUIButton)<any>`
  && {
    text-transform: none;
    font-family: 'IBM Plex Mono', monospace;
  }
`;

export default Button;

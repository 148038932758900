import React, { useContext } from 'react';
import { Flex } from '@rebass/grid/emotion';
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { Login } from '../../modules/Login';
import ProjectList from '../../modules/Project/List';
import ProjectDashboard from '../../modules/Project/Details';
import ProjectCreate from '../../modules/Project/Create';
import ProjectReport from '../../modules/Project/Report';
import Sidebar from '../../modules/Sidebar';
import * as S from './App.styles';
import GithubAuth from '../../modules/Login/GithubAuth';
import BitbucketAuth from '../../modules/Login/BitbucketAuth';

import { UserContext } from 'Lib/UserProvider';

const App = (props: RouteComponentProps) => {
  const { me, loading } = useContext(UserContext);

  const userIsLoggedIn = !!(me && me.id);

  const renderMainContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    return userIsLoggedIn ? (
      <Switch>
        <Route exact path="/dashboard" component={ProjectList} />
        <Route exact path="/projects/create" component={ProjectCreate} />
        <Route exact path="/projects/:projectId" component={ProjectDashboard} />
        <Route
          exact
          path="/projects/:projectId/reports/:reportId"
          component={ProjectReport}
        />
        <Route component={ProjectList} />
        />
      </Switch>
    ) : (
      <Flex
        alignItems="center"
        justifyContent="center"
        width={1}
        style={{ height: '100%' }}
      >
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/auth/github" component={GithubAuth} />
          <Route path="/auth/bitbucket" component={BitbucketAuth} />
          <Route component={Login} />
        </Switch>
      </Flex>
    );
  };

  return (
    <Flex style={{ height: '100%' }}>
      <Sidebar userIsLoggedIn={userIsLoggedIn} />

      <S.Main>{renderMainContent()}</S.Main>
    </Flex>
  );
};

export default withRouter(App);

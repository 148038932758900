import React from 'react';
import { ReportDetailItem } from '../../../types';
import { Box } from '@rebass/grid/emotion';
import moment from 'moment';

type Props = {
  item: ReportDetailItem;
  repositoryUrl: string;
  branchName: string;
};

const getRepoType = (repo: string) => {
  if (!repo) return null;
  if (repo.match(/bitbucket/)) {
    return 'bitbucket';
  }
  if (repo.match(/github/)) {
    return 'github';
  }
  return null;
};

const getBaseFileUrl = (repo: string, branchName: string = 'master') => {
  const repoType = getRepoType(repo);

  if (!repo) return null;
  if (repoType === 'bitbucket') {
    return `${repo}/src/${branchName}/`;
  }
  if (repoType === 'github') {
    return `${repo}/tree/${branchName}/`;
  }
  return null;
};

const getBaseCommitUrl = (repo: string) => {
  const repoType = getRepoType(repo);

  if (!repo) return null;
  if (repoType === 'bitbucket') {
    return `${repo}/commits/`;
  }
  if (repoType === 'github') {
    return `${repo}/commit/`;
  }
  return null;
};

type FileLinkProps = {
  branchName: string;
  repositoryUrl: string;
  fileName: string;
  lineNumber: number;
};

const FileLink = (props: FileLinkProps) => {
  const repoType = getRepoType(props.repositoryUrl);
  const baseUrl = getBaseFileUrl(props.repositoryUrl, props.branchName);

  let href;
  if (repoType === 'github') {
    href = `${baseUrl}${props.fileName}#L${props.lineNumber}`;
  }
  if (repoType === 'bitbucket') {
    href = `${baseUrl}${props.fileName}#lines-${props.lineNumber}`;
  }

  if (href) {
    return (
      <a target="_blank" href={href}>
        {props.fileName}:{props.lineNumber}
      </a>
    );
  }

  return (
    <span>
      ${props.fileName}:${props.lineNumber}
    </span>
  );
};

const ReportItem = (props: Props) => {
  const renderCommit = (commitHash: string) => {
    const baseCommitUrl = getBaseCommitUrl(props.repositoryUrl);

    if (!baseCommitUrl || !commitHash) return null;

    return (
      <a target="_blank" href={`${baseCommitUrl}${commitHash}`}>
        {commitHash.substring(0, 8)}
      </a>
    );
  };

  return (
    <li>
      <Box mb={3}>
        <div>{props.item.message}</div>
        <FileLink
          repositoryUrl={props.repositoryUrl}
          fileName={props.item.fileName}
          lineNumber={props.item.lineNumber}
          branchName={props.branchName}
        />
        <div>
          <small>
            {props.item.commitData &&
              Object.getOwnPropertyNames(props.item.commitData).length !==
                0 && (
                <>
                  {renderCommit(props.item.commitData.hash)} |{' '}
                  {props.item.commitData.authorName} |{' '}
                  {moment
                    .unix(props.item.commitData.timestamp)
                    .format('MM-DD-YYYY')}
                </>
              )}
          </small>
        </div>
      </Box>
    </li>
  );
};

export default ReportItem;

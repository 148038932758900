import styled from '@emotion/styled';
import { css } from 'emotion';
import { Box, Flex } from '@rebass/grid/emotion';
import colors from 'Lib/theme';
import { BoxProps } from '@rebass/grid';
import Paper from 'Components/Paper';
import MoreVert from '@material-ui/icons/MoreVert';

export const DashedBox = styled(Flex)<BoxProps>`
  border: 1px dashed ${colors.purple};
  border-radius: 8px;
  height: 170px;
  align-items: center;
  justify-content: center;
`;

export const ProjectCard = styled(Paper)`
  height: 170px;
`;

export const ItemDiff = styled(Box)<BoxProps>`
  color: #222;
  font-size: 12px;
`;

export const DiffStyle = css`
  && {
    font-size: 12px;
  }
`;

export const IconMoreVert = styled(MoreVert)`
  color: gray;
`;

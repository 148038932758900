import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid/emotion';
import moment from 'moment';
import Paper from 'Components/Paper';
import Breadcrumbs from 'Components/Breadcrumbs';
import Chart from './components/Chart';
import Button from 'Components/Button';
import IconButton from '@material-ui/core/IconButton';
import Summary from './components/Summary/index';
import * as S from './Details.styles';
import { Report } from '../types';
import Snackbar from 'Components/Snackbar';
import SettingsIcon from '@material-ui/icons/Settings';
import { SettingsDialogue } from '../Settings/index';

type Props = {
  isErrorShown: boolean;
  isRunningReport: boolean;
  name: string;
  projectId: string;
  repositoryUrl: string;
  reports: Report[];
  reportConfig: { [key: string]: string };
  runReport: () => void;
  runReportButtonText: string;
  setIsErrorShown: (isShown: boolean) => void;
  setIsSettingsDialogueOpen: (isOpen: boolean) => void;
  isSettingsDialogueOpen: boolean;
};

const ProjectDetails = (props: Props) => {
  return (
    <>
      <Flex mb={3} width={1} justifyContent="space-between">
        <Breadcrumbs
          links={[
            { url: '/dashboard', text: 'Projects' },
            { text: props.name },
          ]}
        />
        <Flex>
          <Button
            variant="contained"
            color="primary"
            onClick={props.runReport}
            disabled={props.isRunningReport}
          >
            {props.runReportButtonText}
          </Button>
          <Box ml={2}>
            <IconButton
              aria-label="Report settings"
              onClick={() => props.setIsSettingsDialogueOpen(true)}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Flex>
      </Flex>
      <Box mb={4}>
        <S.Title>{props.name}</S.Title>
        <small>
          <a target="_blank" href={props.repositoryUrl}>
            {props.repositoryUrl}
          </a>
        </small>
      </Box>

      {Boolean(props.reports.length) ? (
        <>
          <Chart data={props.reports} />
          <Summary reports={props.reports} />
          {props.reports.map((report: Report) => (
            <Paper key={report.id} p={3} mb={3}>
              <Link to={`/projects/${props.projectId}/reports/${report.id}`}>
                {moment(report.createdAt).format('MMM Do')} - ({report.count}{' '}
                items) - branch: {report.branchName}
              </Link>
            </Paper>
          ))}
        </>
      ) : (
        <Flex width={1} justifyContent="center" alignItems="center" py="150px">
          Get started by running your first report!
        </Flex>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={props.isErrorShown}
        onClose={() => props.setIsErrorShown(false)}
        autoHideDuration={6000}
        message={<span>There was a problem creating the report.</span>}
      />
      <SettingsDialogue
        isOpen={props.isSettingsDialogueOpen}
        setIsOpen={props.setIsSettingsDialogueOpen}
        projectId={props.projectId}
        reportConfig={props.reportConfig}
      />
    </>
  );
};

export default ProjectDetails;

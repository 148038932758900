import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import { BoxProps, FlexProps } from '@rebass/grid';
import Paper from 'Components/Paper';

export const List = styled(Box)<BoxProps>``;

export const ListItem = styled(Paper)`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 16px;
  margin-bottom: 8px;
`;

export const Description = styled(Flex)`
  font-size: 10px;
  color: #8c8a8e;
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PrivateIcon = styled.span`
  color: #d4c279;
  font-size: 16px;
  margin-left: 4px;
`;

export const TinyAvatar = styled.div`
  height: 12px;
  width: 12px;
  background-image: ${({ avatarUrl }: { avatarUrl: string }) =>
    `url(${avatarUrl})`};
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  margin-right: 4px;
`;

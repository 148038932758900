import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import SettingsContainer from './SettingsContainer';
import * as S from './Settings.styles';

type Props = {
  isOpen: boolean;
  projectId: string;
  reportConfig: { [key: string]: string };
  setIsOpen: (isOpen: boolean) => void;
};

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

const SettingsDialogue = (props: Props) => {
  const handleClose = () => props.setIsOpen(false);

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="md"
      disableBackdropClick={true}
      scroll="body"
      BackdropProps={{
        classes: {
          root: S.DialogueBackdrop,
        },
      }}
    >
      <SettingsContainer
        closeDialogue={handleClose}
        projectId={props.projectId}
        reportConfig={props.reportConfig}
      />
    </Dialog>
  );
};

export default SettingsDialogue;

import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import moment from 'moment';
import * as S from './Chart.styles';
import { Report } from '../../../types';
import colorPallete from 'Lib/theme';

const colors = Object.values(colorPallete);

type Props = {
  data: Report[];
};

const CustomizedTooltip = (props: TooltipProps) => {
  if (props.active && props.payload) {
    return (
      <S.Tooltip>
        {props.payload.map(data => {
          return (
            <S.TooltipItem key={`${data.dataKey}`}>
              <strong>{data.dataKey}</strong> - {data.value}
            </S.TooltipItem>
          );
        })}
      </S.Tooltip>
    );
  }

  return null;
};

const Chart = (props: Props) => {
  if (!props.data[0]) return <div>no reports yet...</div>;
  const firstReport = props.data[0];

  const fillerData = new Array(12).fill({ createdAt: Infinity });

  const reportSumarries = fillerData
    .map((filler, idx) => {
      const report = props.data[idx];
      if (report) {
        return {
          ...report.reportSummary,
          createdAt: new Date(report.createdAt).getTime(),
          linesOfCode: report.linesOfCode,
        };
      }
      return filler;
    })
    .sort((a, b) => a.createdAt - b.createdAt);

  const labels = Object.keys(firstReport.reportSummary).sort(
    (a, b) =>
      Number(firstReport.reportSummary[b]) -
      Number(firstReport.reportSummary[a]),
  );

  const formatXAxis = (tickItem: number) => {
    if (tickItem === Infinity) return '';
    return moment(tickItem).format('MMM Do');
  };

  return (
    <ResponsiveContainer height={300}>
      <BarChart height={400} data={reportSumarries} barGap={4}>
        <Tooltip content={<CustomizedTooltip />} cursor={false} />
        <XAxis
          dataKey="createdAt"
          domain={['dataMin', 'dataMax']}
          tickFormatter={formatXAxis}
          tick={{ fontSize: 12 }}
        />
        <YAxis yAxisId="techDebtLabels" tick={{ fontSize: 12 }} />
        {labels.map((label: string, idx: number) => (
          <Bar
            yAxisId="techDebtLabels"
            key={label}
            maxBarSize={100}
            dataKey={label}
            fill={colors[idx]}
            stackId="createdAt"
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;

import React, { useState } from 'react';
import moment from 'moment';
import * as S from './Chart.styles';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  TooltipProps,
} from 'recharts';
import { Project } from '../../List.container';

type Props = {
  maxItemTotalCount: number;
  reports: Project['reports'];
};

const CustomizedTooltip = (props: TooltipProps) => {
  if (props.active && props.payload) {
    const data = props.payload[0].payload;

    if (!data.createdAt) {
      return null;
    }

    return (
      <S.Tooltip>
        <S.TooltipItem>{data.count} items</S.TooltipItem>
        <S.TooltipItem>
          {moment(data.createdAt).format('ddd, MMMM Do YYYY, h:mm:ss a')}
        </S.TooltipItem>
      </S.Tooltip>
    );
  }

  return null;
};

const Chart = (props: Props) => {
  const filledReports = new Array(12).fill({ count: 0 }).map((item, idx) => {
    if (props.reports[idx]) {
      return props.reports[idx];
    }
    return item;
  });

  return (
    <S.Chart>
      <ResponsiveContainer height={60}>
        <BarChart data={filledReports}>
          <Bar barSize={15} dataKey="count" fill="#ada3c1" />
          <YAxis
            type="number"
            domain={[0, props.maxItemTotalCount]}
            hide={true}
          />
          <XAxis dataKey="createdAt" hide={true} />
          <Tooltip content={<CustomizedTooltip />} cursor={false} />
        </BarChart>
      </ResponsiveContainer>
    </S.Chart>
  );
};

export default Chart;

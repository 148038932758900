import styled from '@emotion/styled';

export const Value = styled.div`
  font-size: 48px;
  font-weight: 700;
`;

export const Name = styled.div`
  color: gray;
  font-weight: 100;
  font-size: 20px;
`;

import React from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import * as S from './Login.styles';

type Props = {
  authenticateWithBitbucket: () => void;
  authenticateWithGithub: () => void;
};

const Login = (props: Props) => {
  return (
    <Box width="500px">
      <h1>Login to Morbo</h1>
      <p>You'll be taken to Github or Bitbucket to authenticate.</p>
      <Flex mt={4}>
        <Box mr={3}>
          <S.GithubButton
            variant="contained"
            color="inherit"
            size="large"
            onClick={props.authenticateWithGithub}
          >
            Login with Github
          </S.GithubButton>
        </Box>
        <Box>
          <S.BitBucketButton
            variant="contained"
            color="inherit"
            size="large"
            onClick={props.authenticateWithBitbucket}
          >
            Login with Bitbucket
          </S.BitBucketButton>
        </Box>
      </Flex>
    </Box>
  );
};

export default Login;

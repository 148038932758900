import React, { useState } from 'react';
import { Box } from '@rebass/grid/emotion';
import Popover from '@material-ui/core/Popover';
import IconMoreVert from '@material-ui/icons/MoreVert';
import Button from 'Components/Button';
import * as S from './OverflowMenu.styles';

type Props = {
  projectId: string;
  untrackProject: (id: string) => () => void;
};

const OverflowMenu = (props: Props) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const handleClickAnchor = (event: any) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  return (
    <>
      <S.IconButton onClick={handleClickAnchor}>
        <IconMoreVert />
      </S.IconButton>

      <Popover
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={3}>
          <Button onClick={props.untrackProject(props.projectId)} size="small">
            Untrack Project
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default OverflowMenu;

import React, { useState } from 'react';
import { Box, Flex } from '@rebass/grid/emotion';
import { Me } from 'Lib/UserProvider';
import * as S from './LoggedInSidebar.styles';
import Popover from '@material-ui/core/Popover';
import Button from 'Components/Button';

type Props = {
  logout: () => void;
  user: Me | null;
};

const Sidebar = (props: Props) => {
  const avatarUrl = (props.user && props.user.avatarUrl) || '';
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const handleClickAnchor = (event: any) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  return (
    <Box>
      <S.Avatar
        onClick={handleClickAnchor}
        size={40}
        src={avatarUrl}
        style={{
          boxShadow: '0 0 0 3px white, 0 0 0 4px #d2d2d2',
        }}
      />
      <Popover
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box p={3}>
          <Flex alignItems="center" mb={3}>
            <Box mr={3}>
              <S.Avatar onClick={handleClickAnchor} size={50} src={avatarUrl} />
            </Box>
            <Box>
              <Box>
                <strong>{props.user && props.user.name}</strong>
              </Box>
              <Box>
                <small>{props.user && props.user.email}</small>
              </Box>
            </Box>
          </Flex>
          <Button variant="outlined" size="small" onClick={props.logout}>
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default Sidebar;
